<template>
  <div class="main" >
    <div class="main_box" v-loading="loading1">
      <div class="flex_row_center main_announcement">
        <i class="el-icon-message-solid"></i>
        <div class="main_announcement_item">
          <span>今日更新56847条</span>
        </div>
      </div>
      <template v-if="isRecruitInfo">
        <div class="flex_row_center main_add" style="justify-content: space-between;" @click="dialogVisible = true">
          <div class="flex_center main_add_l">
            <span>我的关注</span>
          </div>
          <div class="main_add_r">
            <span>点击编辑</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex_row_center main_add" @click="dialogVisible = true">
          <div class="flex_center main_add_l">
            <div class="main_add_icon">
              <el-image :src="require('@/assets/images/icon/icon_1.png')"></el-image>
            </div>
          </div>
          <div class="flex_column main_add_r">
            <span>添加关注，为您推荐更精确的内容！</span>
            <el-button type="primary" class="main_add_r_button">添加关注</el-button>
          </div>
        </div>
      </template>
      <RecruitTable :dataObj="dataObj" @toDetail="toDetail" @handleCurrentChange="handleCurrentChange" />
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      title="添加关注"
      append-to-body
      width="900px"
    >
      <div slot="title" class="main_dialog">添加关注</div>
      <div class="main_form">
        <div class="main_form_list">
          <div class="flex_center main_form_list_item">
            <div class="main_form_list_item_title">选择地区：</div>
            <div class="flex main_form_list_item_center">
              <el-popover
                placement="bottom"
                width="746"
                trigger="click">
                <div class="flex main_dialog_popover">
                  <div class="main_dialog_popover_l">
                    <div :class="['single_ellipsis', 'main_dialog_popover_l_item', provinceNameSelect === item.name && 'main_dialog_popover_l_item_on']" v-for="(item, index) in addressData" :key="item.adCode" @click="onProvince(item)">
                      <span>{{item.name}}</span>
                    </div>
                  </div>
                  <div class="flex main_dialog_popover_r">
                    <div :class="['main_dialog_popover_r_item', checkCitySelect(item) && 'main_dialog_popover_r_item_on']" v-for="(item, index) in cityList" :key="item.adCode" @click="onCity(item)">
                      <span>{{item.name}}</span>
                    </div>
                  </div>
                </div>
                <el-input class="main_search_input" slot="reference" :value="displayCityNames.join()" placeholder="请输入诚信记录" style="width: 100%;"></el-input>
              </el-popover>
            </div>
          </div>
          <div class="flex_center main_form_list_item">
            <div class="main_form_list_item_title">发布时间：</div>
            <div class="flex main_form_list_item_center">
              <div class="flex main_form_list_item_center_margin">
                <div :class="item.value === publishDate ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in releaseTime" :key="item.value" @click="onTime(item)">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex_column main_form_list_item">
            <div class="main_form_list_item_title" style="padding-bottom: 10px;">请添加您的企业资质<span>（推荐符合您资质的项目）</span></div>
            <div class="flex main_form_list_item_center">
              <el-select
                v-model="qualification"
                multiple
                filterable
                remote
                reserve-keyword
                placeholder="请输入企业资质"
                :remote-method="remoteMethod"
                :loading="loading"
                @visible-change="blurQualification"
              >
                <el-option
                  v-for="item in qualificationList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="flex_column main_form_list_item">
            <div class="main_form_list_item_title" style="padding-bottom: 10px;">业务关键词<span>（关键词越多，推荐资讯越精髓）</span></div>
            <div class="flex main_form_list_item_center">
              <div class="flex_center main_form_list_item_center_confirm">
                <el-select
                  v-model="bussKeyword"
                  multiple
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入建设单位名称"
                  :remote-method="remoteMethod1"
                  :loading="loading"
                  @visible-change="blurQualification1"
                >
                  <el-option
                    v-for="item in bussKeywordList"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
                <el-button type="primary" class="main_form_list_item_center_confirm_btn" @click="onBussKeyword">添加</el-button>
              </div>
            </div>
          </div>
          <div class="flex_column main_form_list_item">
            <div class="flex_center main_form_list_item_title" style="justify-content: space-between; margin-bottom: 7px;">
              <span>热门关键词</span>
              <div class="flex_center main_form_list_item_title_display">
                <div class="flex_center main_form_list_item_title_display_icon" @click="getCommonSearchHot">
                  <span>刷新</span>
                  <i :class="['el-icon-refresh-right', loading2 && 'rotation_360']"></i>
                </div>
                <div class="main_form_list_item_title_display_link"></div>
                <div class="flex_center main_form_list_item_title_display_icon" @click="onExpansion = !onExpansion">
                  <span>收起</span>
                  <i :class="['el-icon-arrow-up', onExpansion ? 'rotation_180' : 'rotation_180_off']"></i>
                  <!--<i class="el-icon-arrow-down"></i>-->
                </div>
              </div>
            </div>
            <div class="flex main_form_list_item_center" v-loading="loading2" v-show="onExpansion">
              <div class="flex_center main_form_list_item_center_confirm">
                <div class="flex main_form_list_item_center_margin" style="margin: 0 -13px;">
                  <div class="main_form_list_item_center_margin_item" v-for="(item, index) in hotList" :key="index" @click="onHot(item)">
                    <span>{{item}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onErpTbFocusReset">重 置</el-button>
        <el-button type="primary" @click="onErpTbFocusSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { throttle } from '@/utils/public';
  import { urlCommonResRecommendList, urlCommonQualificationTypeProPage, urlCommonSearchHot, urlErpTbFocusMy, urlErpTbFocusSave, urlCommonSearchAuto, urlErpCommonResListPage2 } from '@/api/recruit';
  import { mapState } from 'vuex'
  export default {
    name: "recruit",
    components: { RecruitTable: () => import('./components/recruit-table.vue') },
    data() {
      return {
        dataObj: {},
        cityList: [],
        provinceNameSelect: '',
        hotList: [], // 弹窗热门关键字列表
        releaseTime: [
          {name: '当天', value: 1},
          {name: '三天内', value: 3},
          {name: '一周内', value: 7},
          {name: '15天内', value: 15},
          {name: '30天内', value: 30}
        ],
        onExpansion: true,
        dialogVisible: false,
        loading: false,
        loading1: true,
        loading2: false,
        qualificationList: [],

        selectedCities: [],
        bussKeywordList: [],
        bussKeyword: [],
        publishDate: 1,
        qualification: [],
        province: '广西壮族自治区',
        city: '',
        district: '',
        isRecruitInfo: false
      }
    },
    async mounted() {
      document.title = '招标投标-首页' //直接等于需要修改的名字即可
      await this.getErpTbFocusMy();
      this.getDataList();
      this.getCommonQualificationTypeProPage();
      this.getCommonSearchHot();
      this.getCommonSearchAuto();
    },
    computed: {
      ...mapState({
        addressData: state => state.address.addressData
      }),
      displayCityNames() {
        return this.selectedCities.map(item => {
          let resName = item.name
          if (resName == '直辖市' && item.pname) {
            resName = item.pname 
          }
          return resName
        })
      }
    },
    watch: {
      addressData: {
        handler(val) {
          if (!val.length) return;
          this.provinceNameSelect = this.addressData[0].name;
          this.cityList = this.addressData[0].child;
          return val;
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      // 跳转详情页
      toDetail(e) {
        this.$router.push({path: "/details/index", query: { tenderingCode: e }});
      },
      // 获取用户编辑信息
      async getErpTbFocusMy() {
        const res = await urlErpTbFocusMy({});
        if (!res.data) {
          return
        }
        
        const { areaName, areas, bussKeyword, publishDate, qualification } = res.data;
        if (areas) {
          let codeList = areas.split(',')
          this.playbackCityName(codeList)
        }
        this.bussKeyword = bussKeyword === '' ? bussKeyword.split('') : bussKeyword.split(',');
        this.publishDate = publishDate;
        this.qualification = qualification === '' ? qualification.split('') : qualification.split(',');
        if (areaName || areas || bussKeyword || qualification) {
          this.isRecruitInfo = true;
          return;
        }
        this.isRecruitInfo = false;
      },
      // 获取用户保存的地区后，回显。传入地区代码列表
      playbackCityName(codeList) {
        if (!codeList || !this.addressData) {
          return
        }
        let list = []
        this.addressData.forEach(province => {
          if (province.child && province.child.length) {
            province.child.forEach(city => {
              if (codeList.includes(String(city.adCode))) {
                if (!city.pname) {
                  city.pname = province.name
                }
                list.push(city)
              }
            })
          }
        });
        this.selectedCities = list
      },
      checkCitySelect(city) {
        return this.selectedCities.some(item => item.adCode == city.adCode)
      },
      // 获取业务关键字
      async getCommonSearchAuto() {
        const param = {
          current: 1,
          size: 100,
          text: this.addBussKeyword || '',
          appid: 'wx9e583ad05086ccdd'
        };
        const res = await urlCommonSearchAuto(param);
        let list = res.data || []
        list = list.filter((item, index, arr) => arr.indexOf(item) == index)
        this.bussKeywordList = list;
        console.log('----去重后的关键词列表', list)
      },
      // 重置
      onErpTbFocusReset() {
        this.selectedCities = [];
        this.bussKeyword = [];
        this.publishDate = 1;
        this.qualification = [];
        this.onErpTbFocusSave();
      },
      remoteMethod1: throttle(function(e) {
        this.addBussKeyword = e;
        this.getCommonSearchAuto()
      }, 500),
      // 添加关键词
      onBussKeyword() {
        if (!this.addBussKeyword) return;
        this.bussKeyword.push(this.addBussKeyword);
        this.addBussKeyword = ''
      },
      // 点击企业资质下拉框显示与否
      blurQualification(type) {
        if (!type) this.getCommonQualificationTypeProPage()
      },
      // 点击业务关键字下拉框显示与否
      blurQualification1(type) {
        if (!type) this.getCommonSearchAuto()
      },
      // 输入企业资质数据回调
      remoteMethod: throttle(function(e) {
        this.getCommonQualificationTypeProPage(e)
      }, 500),
      // 保存
      async onErpTbFocusSave() {
        let aeraStr = this.selectedCities.map(item => item.adCode).join(',')
        const param = {
          areaName: this.displayCityNames?.join(),
          areas: aeraStr,
          bussKeyword: this.bussKeyword?.join(),
          publishDate: this.publishDate,
          qualification: this.qualification?.join()
        };
        const res = await urlErpTbFocusSave(param);
        if (res.code === 200 && res.msg === '操作成功') {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.dialogVisible = false;
          this.getErpTbFocusMy();
          this.getDataList();
          return ;
        }
        this.$message.error('保存失败');
      },
      // 地区市级选择
      onCity(val) {
        console.log('---------点击城市', val)
        let existedIndex = this.selectedCities.findIndex(item => item.adCode == val.adCode)
        if (existedIndex > -1) {
          this.selectedCities.splice(existedIndex, 1)
        } else {
          let cityObj = {
            ...val,
          }
          if (cityObj.name == '直辖市' && !cityObj.pname) {
            cityObj.pname = this.provinceNameSelect
          }
          this.selectedCities.push(cityObj)
        }
        console.log('---------城市选中列表', [...this.selectedCities])
      },
      // 地区省级选择
      onProvince(val) {
        this.cityList = val.child;
        this.provinceNameSelect = val.name;
      },
      // 选择发布时间
      onTime(e) {
        if (this.publishDate === e.value) return;
        this.publishDate = e.value;
      },
      // 选择热门关键词
      onHot(value) {
        if (this.bussKeyword.includes(value)) {
          this.bussKeyword.splice(this.bussKeyword.indexOf(value), 1);
        } else {
          this.bussKeyword.push(value);
        }
      },
      // 热门关键词接口数据
      async getCommonSearchHot() {
        this.loading2 = true;
        const param = `appid=wx9e583ad05086ccdd`
        const res = await urlCommonSearchHot(param);
        this.hotList = res.data;
        setTimeout(() => this.loading2 = false, 500)
      },
      // 企业资质接口数据
      async getCommonQualificationTypeProPage(name) {
        this.loading = true;
        const param = {
          current: 1,
          name: name || '',
          size: 100
        };
        const res = await urlCommonQualificationTypeProPage(param);
        this.qualificationList = res.data.records;
        this.loading = false;
      },
      // 获取首页数据信息
      async getDataList(current) {
        this.loading1 = true;
        if (this.$route.query?.search) {
          const param = {
            current: current || 1,
            isLocation: false,
            search: this.$route.query.search,
            size: 20
          };
          const res = await urlErpCommonResListPage2(param);
          this.dataObj = res.data;
          this.loading1 = false;
          return;
        }
        const param = {
          current: current || 1,
          size: 20,
          province: this.province,
          city: this.city,
          district: this.district
        };
        const res = await urlCommonResRecommendList(param);
        this.dataObj = res.data;
        this.loading1 = false;
      },
      // 分页
      handleCurrentChange(val) {
        this.getDataList(val);
      }
    }
  }
</script>

<style scoped lang="less">
  /deep/.el-dialog {
    border-radius: 4px;
  }
  /deep/.el-dialog__header {
    padding: 0;
    .main_dialog {
      height: 58px;
      padding: 20px;
      border-bottom: 1px solid #DCDCDC;
      box-sizing: border-box;
    }
  }
  /deep/.el-dialog__body {
    padding: 0;
  }
  .main {
    .main_box {
      .main_announcement {
        height: 40px;
        margin: 16px 0;
        background-color: #FFF8DD;
        border-radius: 4px;
        color: #F59800;
        .main_announcement_item {
          padding-left: 10px;
          font-size: 16px;
        }
      }
      .main_add {
        height: 100px;
        padding: 0 20px;
        margin-bottom: 20px;
        border-radius: 4px;
        background: linear-gradient(0deg, #DFF0FF 0%, #F9FCFF 100%);
        box-shadow: 0 3px 6px 1px rgba(3,96,230,0.06);
        cursor: pointer;
        .main_add_l {
          font-size: 16px;
          color: #1890FF;
          .main_add_icon {
            width: 84px;
            min-width: 84px;
            height: 84px;
            margin-right: 30px;
          }
        }
        .main_add_r {
          font-size: 16px;
          color: #1890FF;
          .main_add_r_button {
            width: 100px;
            min-width: 100px;
            height: 24px;
            padding: 0;
            margin-top: 16px;
          }
        }
      }
    }
  }
  .main_form {
    padding: 20px;
    .main_form_list {
      .main_form_list_item {
        padding-bottom: 30px;
        .main_form_list_item_title {
          padding-right: 10px;
          font-size: 16px;
          color: #4E4E4E;
          > span {
            color: #888888;
          }
          .main_form_list_item_title_display {
            .main_form_list_item_title_display_icon {
              font-size: 16px;
              color: #4E4E4E;
              line-height: 1;
              cursor: pointer;
              > span, > i {
                padding: 0 3px;
              }
            }
            .main_form_list_item_title_display_link {
              width: 1px;
              height: 14px;
              background-color: #4E4E4E;
              margin: 0 17px;
            }
          }
        }
        .main_form_list_item_center {
          flex: 1;
          > span {
            width: 100%;
          }
          /deep/ .el-cascader, /deep/.el-select {
            width: 100%;
          }
          .main_form_list_item_center_margin {
            margin:  0 -11px;
            .main_form_sort_item {
              height: 32px;
              line-height: 32px;
              padding: 0 8px;
              margin: 0 11px;
              cursor: pointer;
            }
            .main_form_sort_item_on {
              background-color: #EDF4FF;
              border-radius: 4px;
              color: #1890FF;
            }
            .main_form_list_item_center_margin_item {
              height: 32px;
              line-height: 32px;
              padding: 0 8px;
              margin: 5px 13px;
              cursor: pointer;
              background-color: #F6F7FB;
              color: #4E4E4E;
            }
            .main_form_list_item_center_margin_item:hover {
              background-color: #EDF4FF;
            }
          }
          .main_form_list_item_center_confirm {
            flex: 1;
            .main_form_list_item_center_confirm_input {
              flex: 1;
            }
            .main_form_list_item_center_confirm_btn {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .main_dialog_popover {
    height: 248px;
    margin: -12px;
    .main_dialog_popover_l {
      width: 140px;
      min-width: 140px;
      height: 100%;
      overflow-y: scroll;
      border-right: 1px solid #DCDCDC;
      .main_dialog_popover_l_item {
        height: 40px;
        padding: 0 20px;
        font-size: 18px;
        font-weight: 500;
        color: #4E4E4E;
        line-height: 40px;
        background-color: #fff;
        cursor: pointer;
      }
      .main_dialog_popover_l_item:hover {
        background-color: #F5F7FA;
      }
      .main_dialog_popover_l_item_on {
        position: relative;
        color: #1890FF;
      }
      .main_dialog_popover_l_item_on::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 12px;
        border-radius: 4px;
        background-color: #1890FF;
      }
    }
    .main_dialog_popover_r {
      margin: -6px -8px;
      padding: 14px 20px;
      flex-wrap: wrap;
      align-content: flex-start;
      .main_dialog_popover_r_item {
        height: 32px;
        padding: 0 8px;
        margin: 6px 8px;
        border-radius: 4px;
        background-color: #F6F7FB;
        line-height: 32px;
        font-size: 16px;
        color: #4E4E4E;
        cursor: pointer;
      }
      .main_dialog_popover_r_item_on {
        background-color: #EDF4FF;
        color: #1890FF;
      }
      .main_dialog_popover_r_item:hover {
        background-color: #EDF4FF;
      }
    }
  }
</style>
